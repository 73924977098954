<template>
    <div>
        <iframe style="height: 80vh;width: 70vw;" src="https://crm.myamera.com/admin.php?action=login&ctrl=Auth"
            title="Crm">
        </iframe>
    </div>
</template>
  
<script>

export default {
    data() {
        return {}
    },
}
</script>
  
<style></style>
  